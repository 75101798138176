var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"list"},[_c('h1',{staticClass:"mb-5",attrs:{"align":"center"}},[_vm._v("Catalogue Stock")]),_c('v-template',[_c('v-btn',{staticClass:"styled-input",attrs:{"outlined":"","dense":""},on:{"click":function($event){return _vm.moveTo()}}},[_vm._v(" Catalogue Manager ")])],1),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.periode,"label":"Periode","item-value":"ID_PERIOD"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.periodeCombine(data.item.YEAR_START))+" / "+_vm._s(_vm.periodeCombine(data.item.YEAR_FINISH))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.periodeCombine(data.item.YEAR_START))+" / "+_vm._s(_vm.periodeCombine(data.item.YEAR_FINISH))+" ")]}}]),model:{value:(_vm.selectedPeriode),callback:function ($$v) {_vm.selectedPeriode=$$v},expression:"selectedPeriode"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(" "+_vm._s(_vm.status(item))+" ")])]}},{key:"item.valueDiff",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.myComputed(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-2","small":""},on:{"click":function($event){return _vm.editHandler(item)}}},[_vm._v("mdi-checkbox-marked-circle")])]}},{key:"item.stok",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"centered-input text--darken-3 mt-3",model:{value:(item.STOCK_GUDANG),callback:function ($$v) {_vm.$set(item, "STOCK_GUDANG", $$v)},expression:"item.STOCK_GUDANG"}})]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("warning!")])]),_c('v-card-text',[_vm._v(" Anda yakin ingin mengubah stok gudang katalog ini? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogConfirm = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Submit ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.color,"timeout":"2000","bottom":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }